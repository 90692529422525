export const MenuItems = [
    {
        title : "Home",
        url : "/",
        cName : "nav-links",
       
    },

    {
        title : "About",
        url : "/about",
        cName : "nav-links",
        
    },

    {
        title : "Service",
        url : "/service",
        cName : "nav-links",
        
    },

    {
        title : "Gallery",
        url : "/gallery",
        cName : "nav-links",
       
    },  
    
    {
        title : "Destination",
        url : "/destination",
        cName : "nav-links",
       
    },  

]